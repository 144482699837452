import { Api } from 'lib/api'
import { IResponseData } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICampaignResponse } from './types'

export const getCampaignSingle = (slug: string) => {
  return Api
    .get<IResponseData<ICampaignResponse>>(`partner/agency/campaign/${slug}/info`)
    .then(res => fromResponseToData(res.data))
}
