import { Api } from 'lib/api'
import { IResponse } from 'lib/types/response'

import { ICampaignsResponse } from '../types'

import { fromResponseToData } from './adapter'

export const getCampaignsClosedList = (data: { page: number }) => {
  return Api
    .get<IResponse<ICampaignsResponse>>('partner/agency/campaigns/closed', data)
    .then(res => fromResponseToData(res))
}
