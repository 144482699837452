import { IAdvertiser, IStreamerAd } from 'lib/types/ad'
import { DateTimeType, IdType } from 'lib/types/base-types'
import { ICampaignFormat } from 'lib/types/campaign-format'
import { CampaignStatus, CompletedCampaignStatus } from 'lib/types/campaign-status'
import { IHolding } from 'lib/types/holding'
import { PlatformType } from 'lib/types/platform'

import { Currency, CurrencyType } from '../currency'

export enum CampaignBlockReason {
  MODERATOR = 'moderator',
  LOW_CTR = 'low_ctr',
}

export interface IStreamerAwarenessCampaign {
  id: IdType
  slug: string
  title: string
  format: ICampaignFormat
  description: string
  logo: string
  impressions: number
  limit: {
    daily: number
    restPercent: number
    creatorDaily: number
    creatorGlobal: number
    creatorCampaign: number
  }
  estimateIncome: number
  currency: Currency
  ctr: number
  start: DateTimeType
  end: DateTimeType
  status: CampaignStatus
  restore: boolean
  blocked_at: string | null
  blocked_reason: CampaignBlockReason | null
  dailyActionLimit: {
    enabled: boolean,
    today: null | number,
    todayLimit: null | number
  }
}

export interface IStreamerAwarenessCampaigns {
  active: IStreamerAwarenessCampaign[]
  inactive: IStreamerAwarenessCampaign[]
  future: IStreamerAwarenessCampaign[]
  unavailable: IStreamerAwarenessCampaign[]
}

export interface IStreamerCompletedAwarenessCampaign {
  id: IdType
  slug: string
  title: string
  description: string
  platform: PlatformType
  logo: string
  income: number
  estimateIncome: number
  ctr: number
  avgCtr: number
  streamers:number
  start: DateTimeType
  end: DateTimeType
  participated: boolean
  status: CompletedCampaignStatus
  currency: Currency
}

export type IStreamerCompletedAwarenessCampaigns = IStreamerCompletedAwarenessCampaign[]

export interface IStreamerAwarenessCampaignDetails {
  slug: string
  title: string
  income: number
  estimateIncome: number
  ctr: number
  avgCtr: number
  totalImpressions: number
  impressionsLimit: number
  clicks: number
  start: DateTimeType
  end: DateTimeType
  creatorPayout: number
  payoutType: string
  bidCap: number
  currency: CurrencyType
  frequency: string
  format: ICampaignFormat
  description: string
  ads: IStreamerAd[]
  campaign: {
    id: IdType
    slug: string
    type: string
    title: string
    description: string
    category: string
    visible: boolean
  }
  holding: IHolding
  advertiser: IAdvertiser
  status: CampaignStatus | CompletedCampaignStatus
  dailyActionLimit: {
    enabled: boolean,
    actionPrice: null | number,
    current: null | number
    totalEarned: null | number
  }
}
