import { ICreator, ICreatorResponse } from '../types'

export const fromResponseToData = (response: ICreatorResponse): ICreator => ({
  externalCpm: {
    video: response.external_cpm.video || '',
    pip: response.external_cpm.pip_video || '',
    custom: response.external_cpm.custom || '',
    interactive: response.external_cpm.interactive || '',
  },
  internalCpm: {
    video: response.internal_cpm.video || '',
    pip: response.internal_cpm.pip_video || '',
    custom: response.internal_cpm.custom || '',
    interactive: response.internal_cpm.interactive || '',
  },
  darkMarketExternalCpm: {
    video: response.dark_market_external_cpm.video || '',
    pip: response.dark_market_external_cpm.pip_video || '',
    custom: response.dark_market_external_cpm.custom || '',
    interactive: response.dark_market_external_cpm.interactive || '',
  },
  darkMarketInternalCpm: {
    video: response.dark_market_internal_cpm.video || '',
    pip: response.dark_market_internal_cpm.pip_video || '',
    custom: response.dark_market_internal_cpm.custom || '',
    interactive: response.dark_market_internal_cpm.interactive || '',
  },
})
