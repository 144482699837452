
import { Api } from 'lib/api'
import { IResponse } from 'lib/types/response'

import { fromResponseToData } from './adapter'
import { ICampaignCreatorsResponse } from './types'

export const getCampaignCreators = (slug: string, page: number) => {
  return Api
    .get<IResponse<ICampaignCreatorsResponse>>(`partner/agency/campaign/${slug}/report`, { page })
    .then(res => fromResponseToData(res))
}
