import { defineStore } from 'pinia'

import { getCampaignCreators, getCampaignsClosedList, getCampaignSingle } from 'lib/api/modules/partner/creators/campaigns'
import { Logger } from 'lib/helpers/handleLog'
import parseSlug from 'lib/helpers/parseSlug'

import { State } from './campaignsActive'

export const useCampaignsClosedStore = defineStore({
  id: 'partner-campaigns-closed',

  state: (): State => ({
    isFetchingData: false,
    isFormUpdating: false,
    list: null,
    listCurrentPage: 1,
    campaign: null,
    campaignSidebarVisible: false,
    campaignSlug: null,
    campaignType: null,
    campaignTitle: null,
    campaignCreators: null,
    campaignCreatorsCurrentPage: 1,
    creatorsSidebarVisible: false,
  }),

  actions: {
    async getCampaigns (page = 1) {
      try {
        this.isFetchingData = true
        const res = await getCampaignsClosedList({ page })
        this.list = res
        this.listCurrentPage = page
      }
      finally {
        this.isFetchingData = false
      }
    },

    async getCampaign (slug: string) {
      this.campaignSidebarVisible = true

      if (typeof slug !== 'string') {
        Logger.warning('There is no slug provided')
      }

      if (this.campaignSlug === slug) {
        return
      }

      try {
        this.isFetchingData = true
        this.campaignType = parseSlug(slug)?.type
        this.campaignSlug = slug
        this.campaignTitle = this._getCampaignTitle(slug)
        const res = await getCampaignSingle(slug)
        this.campaign = res
      }
      finally {
        this.isFetchingData = false
      }
    },

    async getCreators (slug: string, page = 1) {
      this.creatorsSidebarVisible = true

      if (typeof slug !== 'string') {
        Logger.warning('Campaign if is not of type \'string\'')
      }

      try {
        this.isFetchingData = true

        this.campaignTitle = this._getCampaignTitle(slug)
        const res = await getCampaignCreators(slug, page)
        this.campaignCreators = res
      }
      finally {
        this.isFetchingData = false
      }
    },

    _getCampaignTitle (slug: string) {
      return this.list?.data.find(campaign => campaign.slug === slug)?.title || null
    },

    // async updateData () {
    //   if (!this.data || this.isFormUpdating) return

    //   try {
    //     this.isFormUpdating = true
    //     await updateAgency(this.data)
    //   }
    //   finally {
    //     this.isFormUpdating = false
    //   }
    // },
  },
})
